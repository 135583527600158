import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHorse } from '@fortawesome/free-solid-svg-icons'
import Typed from 'react-typed';
import Image from './image'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <FontAwesomeIcon icon={faHorse} size="3x" className="icon"/>
        </div>
        <div className="content">
            <div className="inner">
                <h1>MYRION - ΜΗΡΙΟΝΗΣ</h1>
                <Typed
                    strings={[
                        'Stay Tuned For Something <strong>Amazing</strong>',
                        'Our Website is under <strong>construction</strong>']}
                    typeSpeed={50}
                    backSpeed={30}
                    backDelay={2000}
                    className="message"
                    showCursor={false}
                    loop
                />
                <div style={{ maxWidth: `200px`, margin: `1.45rem auto` }}>
                    <Image />
                </div>
                <h3>ΕΛΛΗΝΙΚΗ ΟΜΑΔΑ ΕΦΙΠΠΗΣ ΤΟΞΟΒΟΛΙΑΣ</h3>
            </div>
        </div>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
